<template>
<div>
  <ScreeningOption/>
</div>
</template>

<script>
import ScreeningOption from "@/components/jobScreeningCriteria/ScreeningOption";
export default {
name: "JobScreenOptionView",
  components: {ScreeningOption}
}
</script>

<style scoped>

</style>
