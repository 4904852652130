<template>
  <v-dialog
      transition="dialog-top-transition"
      width="420"
      v-model="dialog"
      persistent
  >
    <template>
      <v-card class="card">
        <div class="tw-flex title tw-items-center tw-justify-between">
          <h3 class="title">{{ Skipped ? 'Skip' : 'Set' }} Screening Test</h3>
          <span class="tw-cursor-pointer font-weight-300" @click="$emit('close')">X</span>
        </div>

        <div class="tw-mt-8">
          <h4 class="card-text md:tw-w-" v-if="Skipped">You can't add a screening test later once you skip this
            process.</h4>

          <h4 class="card-text md:tw-w-" v-else>Candidates would be evaluated based on the selected test. You cannot
            change the test after you have set it..</h4>

          <div class="tw-flex tw-mt-6">
            <v-spacer/>
            <BaseButton :outlined="true" @click="$emit('close')" class="tw-mr-2" width="110px" height="48px"
                        button-text="Cancel"/>
            <BaseButton :loading="loading" :depressed="true" width="110px" height="48px"
                        :button-text="Skipped? 'Skip Test':'Set Test'" @click="submitOrSkip"/>

          </div>
        </div>

      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {mapGetters} from "vuex";
import {
  setTestForJob,
  updateSettingsStatus,
  skipScreeningTest,
} from "@/services/api/APIService";

export default {
  name: "SetScreeningModal",
  components: {BaseButton},
  props: {
    dialog: [Boolean],
    Skipped: [Boolean]

  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('interview', ['getInterviewGrade'])
  },
  methods: {
    submitOrSkip() {
      if (this.Skipped) {
        this.skipTest()
      } else {
        this.submit()
      }
    },
    submit() {
      this.loading = true
      if (this.$route.query.from === 'admin-workspace-programs-programdetails') {
        updateSettingsStatus({id: +this.$route.query.jobId},{
          published: true,
        }).then(res => {
          console.log(res)
          this.$displaySnackbar({message: 'Screening test updated', success: true})
          this.$emit('close')
          this.goToProgramsDetail()
        }).catch(err => {
          console.log(err)
        }).finally(() => this.loading = false)
      } else {
        setTestForJob({job_id: this.$route.query.jobId}).then(res => {
          this.$displaySnackbar({message: res.data.detail, success: true})
          this.$emit('close')
          this.$router.push({name: 'AllCandidates', params: {id: this.$route.query.jobId}})

        })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {
              this.loading = false
            })
      }
    },
    goToProgramsDetail() {
      let token = sessionStorage.getItem('userToken')
      window.location.href =
          `${process.env.VUE_APP_ORGANIZATION_URL}/admin/workspace/programs/programdetails/?id=${this.$route.query.jobId}&programTitle=${this.$route.query.title}&view=courses&token=${token}`
    },
    skipTest() {
      this.loading = true
      if (this.$route.query.from === 'admin-workspace-programs-programdetails') {
        this.goToProgramsDetail()
      } else {
        skipScreeningTest(this.$route.query.jobId)
            .then((res) => {
              this.$emit('close')
              this.$router.push({name: 'AllCandidates', params: {id: this.$route.query.jobId}})
              console.log(res)
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {
              this.loading = false
            })
      }
    }


  }

}
</script>

<style scoped>
.card {
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
  padding: 20px;


}

.title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #001343;

}

.card-text {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;

}


</style>
